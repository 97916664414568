<template>
  <AntRow justify="center">
    <AntCol :span="16">
      <AntRow justify="end" v-if="(permissionData.UserLevelPermissions_Edit || []).includes(loginUserData.permission)">
        <span v-if="editting">
          <a @click="onSave()">Save</a>
          <AntDivider type="vertical" />
          <a @click="onCancel()">Cancel</a>
        </span>
        <span v-else>
          <a @click="onEdit()">Edit</a>
        </span>
      </AntRow>

      <!-- <p><b>editedData:</b> {{ editedData }}</p> -->
      <!-- <p><b>editableData:</b> {{ editableData }}</p> -->

      <AntTable
        class="permission-table"
        :columns="columns"
        :dataSource="dataSource"
        :scroll="{ y: 'calc(100vh - 166px)' }"
        :pagination="{
          pageSize: 100,
          hideOnSinglePage: true
        }"
        :showSorterTooltip="false"
        bordered
      >
        <template #bodyCell="{ text, column, record, index }">

          <template v-if="column.dataIndex !== 'permission'">
            <AntSwitch
              v-if="editting"
              :checked="editableData[record.permission]?.[column.dataIndex]"
              @change="val => onChange(record.permission, column.dataIndex, index, val)"
              size="small"
            />
            <AntSwitch
              v-else
              :checked="text"
              size="small"
              disabled
            />
          </template>
        </template>
      </AntTable>

    </AntCol>
  </AntRow>
</template>

<script>
import {
  ref,
  reactive,
  watch,
  // toRaw,
} from 'vue'

import { useRouter } from 'vue-router'

import {
  Row as AntRow,
  Col as AntCol,
  Table as AntTable,
  Switch as AntSwitch,
  Divider as AntDivider,
  message as antMessage,
} from 'ant-design-vue'

import {
  PERMISSION_NAME,
  PERMISSION_OPTIONS,
} from '@/constants/testerPortal'

import { batchWritePermissions } from '@/api/testerportal'

export default {
  name: 'UserList',
  components: {
    AntRow,
    AntCol,
    AntTable,
    AntSwitch,
    AntDivider,
  },
  props: {
    loginUserData: Object,
    permissionData: Object,
    onChangePermissionData: Function,
  },
  setup(props) {
    const router = useRouter()

    const editting = ref(false)
    const editableData = reactive({})
    const editedData = reactive({})
    const editableFields = ['admin', 'ts', 'tsqa', 'cebqa']

    const customSort = (permissions) => {
      // 定義主要分類順序
      const categoryOrder = {
        PlayerList: 1,
        RatioTable: 2,
        UserAgentList: 3,
        UserList: 4,
        UserLevelPermissions: 5,
      }

      // 定義 PlayerList 內的子分類順序
      const playerListSubOrder = {
        View: 1,
        CreatePlayer: 2,
        CreatePlayer_BetLimit_Edit: 3,
        Deposit: 4,
        'Login/doLogin': 5,
        'Login/doLogin_ClientId_Edit': 6,
        NewGameUse_Edit: 7,
        PlayerStatus_View: 8,
        PlayerStatus_Edit: 9,
        RenewBalance: 10,
      }

      // 定義其他分類內的子分類順序
      const subOrder = {
        View: 1,
        Edit: 2,
        CreateUser: 3,
        Delete: 4,
        ResetPassword: 5,
      }

      return permissions.sort((a, b) => {
        const [categoryA, subCategoryA] = a.permission.split('_', 2)
        const [categoryB, subCategoryB] = b.permission.split('_', 2)

        // 先比較主要分類
        if (categoryOrder[categoryA] !== categoryOrder[categoryB]) {
          return categoryOrder[categoryA] - categoryOrder[categoryB]
        }

        // 如果是 PlayerList，使用特定的子分類順序
        if (categoryA === 'PlayerList') {
          const subA = a.permission.replace('PlayerList_', '')
          const subB = b.permission.replace('PlayerList_', '')
          return (playerListSubOrder[subA] || 999) - (playerListSubOrder[subB] || 999)
        }

        // 其他分類使用通用子分類順序
        return (subOrder[subCategoryA] || 999) - (subOrder[subCategoryB] || 999)
      })
    }

    const permissionDataToDataSource = () => customSort(Object.entries(props.permissionData).map(([permission, allowedUserLevels]) => {
      const result = { permission }
      PERMISSION_OPTIONS.forEach(({ value }) => result[value] = allowedUserLevels.includes(value))
      return result
    }))

    const dataSource = ref(Object.keys(props.permissionData).length ? permissionDataToDataSource() : [])

    const columns = [
      {
        title: 'Permission',
        dataIndex: 'permission',
        width: '40%',
        customRender: ({ text }) => text.replace(/(?!^)([A-Z])/g, ' $1').replace('Login/do Login', 'Login / doLogin').replace(/_/g, ' > '),
      },
      ...Object.entries(PERMISSION_NAME).map(([dataIndex, title]) => ({
        title,
        dataIndex,
        width: '15%',
        align: 'center',
      })),
    ]

    watch(props.permissionData, (newVal) => {
      // console.log('watch props.permissionData newVal', toRaw(newVal), 'oldVal', toRaw(oldVal))
      if (Object.keys(newVal).length) dataSource.value = permissionDataToDataSource()
    }, { once: true })

    const onChange = (permissionName, userLevel, index, val) => {
      // 連動邏輯：若 Player List > View 關閉，Player List > xxxx 一併全關閉
      // const permsArr = permissionName.split('_')
      // const permsLen = permsArr.length

      // if (!val && permsArr[permsLen - 1] === 'View') {
      //   dataSource.value.forEach(({ permission }) => {
      //     if (permission.startsWith(permsArr.slice(0, permsLen - 1).join('_'))) {
      //       // dataSource.value[idx][userLevel] = false
      //       // editableData[permission] = Object.keys(data).reduce((acc, key) => {
      //       //   if (editableFields.includes(key)) acc[key] = data[key]
      //       //   return acc
      //       // }, {})
      //       editableData[permission][userLevel] = val
      //     }
      //   })
      // } else {
      editableData[permissionName][userLevel] = val
      editedData[permissionName] = editableData[permissionName]
      // }
    }

    const onSave = async () => {
      const newPermissionData = {}
      const batchWritePermissionsData = Object.entries(editedData).map(([permission, obj]) => {
        const allowedUserLevels = []
        Object.entries(obj).forEach(([userLevel, val]) => {
          if (val) allowedUserLevels.push(userLevel)
        })

        newPermissionData[permission] = allowedUserLevels
        return {
          permission,
          allowedUserLevels,
        }
      })
      // console.log('batchWritePermissionsData', batchWritePermissionsData)
      // console.log('newPermissionData', newPermissionData)

      const messageKey = `writePermissions-${Date.now()}`
      const { data, message } = await batchWritePermissions(batchWritePermissionsData)

      if (data) {
        props.onChangePermissionData(newPermissionData)

        antMessage.success({
          key: messageKey,
          content: 'Update permission successfully',
        })

        editting.value = false

        if (editedData.UserLevelPermissions_View && !editedData.UserLevelPermissions_View[props.loginUserData]) {
          router.replace('/testerportal')
        }
      } else {
        antMessage.error({
          key: messageKey,
          duration: 5,
          content: `Failed to update permission: ${message}`,
        })
      }
    }

    const onEdit = () => {
      editting.value = true
      dataSource.value.forEach((d) => {
        editableData[d.permission] = Object.keys(d).reduce((acc, key) => {
          if (editableFields.includes(key)) acc[key] = d[key]
          return acc
        }, {})
      })
    }

    const emptyObject = obj => Object.keys(obj).forEach(key => delete obj[key])

    const onCancel = () => {
      editting.value = false
      emptyObject(editableData)
    }

    return {
      editting,
      editedData,
      editableData,
      dataSource,
      columns,
      onSave,
      onChange,
      onEdit,
      onCancel,
    }
  },
}
</script>

<style>
.permission-table .ant-table-thead th, .permission-table .ant-table-row td {
  padding: 8px!important;
}
</style>
