<template>
  <div v-if="showFrame" class="iframe-wrapper">
    <iframe class="iframe" :src="loginPlayerData.loginUrl" />
    <div class="iframe-btns">
      <AntButton class="btn" type="primary" @click="onClickOpenFrame">
        <template #icon>
          <BackIcon />
        </template>
        Back
      </AntButton>
      <AntButton class="btn" type="primary" @click="onClickReload">
        <template #icon>
          <ReloadIcon />
        </template>
        Re-load
      </AntButton>
    </div>
  </div>

  <AntRow v-else class="row">
    <AntCol
      :sm="{ span: 24, offset: 0 }"
      :md="{ span: 20, offset: 2 }"
      :lg="{ span: 16, offset: 4 }"
    >
      <a class="link" target="_blank" :href="loginPlayerData.loginUrl">{{ loginPlayerData.loginUrl }}</a>

      <AntRow justify="center">
          <AntTooltip placement="top" title="URL copied successfully!" trigger="click">
            <AntButton class="btn" type="primary" @click="onClickCopy">
              <template #icon>
                <CopyIcon />
              </template>
              Copy URL
            </AntButton>
          </AntTooltip>

        <AntButton class="btn" type="primary" target="_blank" :href="loginPlayerData.loginUrl">
          <template #icon>
            <NewWindowIcon :rotate="90" />
          </template>
          Open in New Tab
        </AntButton>

        <AntButton class="btn" type="primary" @click="onClickOpenFrame">
          <template #icon>
            <FrameIcon />
          </template>
          Open in Frame
        </AntButton>

        <AntButton class="btn" type="primary" @click="onClickReload">
          <template #icon>
            <ReloadIcon />
          </template>
          Re-generate
        </AntButton>

        <AntButton
          v-if="(permissionData.PlayerList_Deposit || []).includes(loginUserData.permission)"
          class="btn"
          color="black"
          @click="onClickDeposit"
        >
          <template #icon>
            <DollarIcon />
          </template>
          Player Deposit
        </AntButton>

        <AntButton class="btn" color="black" @click="onClickLogout">
          <template #icon>
            <LogoutIcon />
          </template>
          Player Logout
        </AntButton>
      </AntRow>

      <AntAlert
        :style="{ marginTop: '32px' }"
        v-if="!!alertState.message"
        :type="alertState.type"
        :message="alertState.message"
        showIcon
        @close="() => {}"
      />
    </AntCol>
  </AntRow>

</template>

<script>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'

import {
  Row as AntRow,
  Col as AntCol,
  Button as AntButton,
  Alert as AntAlert,
  Tooltip as AntTooltip,
} from 'ant-design-vue'

import CopyIcon from '@ant-design/icons-vue/lib/icons/CopyOutlined'
import NewWindowIcon from '@ant-design/icons-vue/lib/icons/SelectOutlined'
import FrameIcon from '@ant-design/icons-vue/lib/icons/ExpandOutlined'
import LogoutIcon from '@ant-design/icons-vue/lib/icons/ExportOutlined'
import ReloadIcon from '@ant-design/icons-vue/lib/icons/ReloadOutlined'
import BackIcon from '@ant-design/icons-vue/lib/icons/LeftOutlined'
import DollarIcon from '@ant-design/icons-vue/lib/icons/DollarOutlined'

export default {
  name: 'EnterGame',
  components: {
    AntRow,
    AntCol,
    AntButton,
    AntAlert,
    AntTooltip,
    CopyIcon,
    NewWindowIcon,
    FrameIcon,
    LogoutIcon,
    ReloadIcon,
    BackIcon,
    DollarIcon,
  },
  props: {
    selectedSourceLine: Object,
    ratioData: Array,
    permissionData: Object,
    loginUserData: Object,
    loginPlayerData: Object,
    fetchAwcApi: Function,
    onChangeLoginPlayerData: Function,
  },
  setup(props) {
    const router = useRouter()
    if (!props.loginPlayerData.userId) router.push('/testerportal/player-list')

    const showFrame = ref(false)
    const alertState = reactive({})

    const onClickCopy = () => navigator.clipboard.writeText(props.loginPlayerData.loginUrl)

    const onClickOpenFrame = () => showFrame.value = !showFrame.value

    const onClickReload = () => props.fetchAwcApi({
      api: props.loginPlayerData.loginFunction,
      requestData: props.loginPlayerData.loginRequestData,
      onSuccess: (awcData) => {
        props.onChangeLoginPlayerData({ loginUrl: awcData.url })
      },
      showMessage: true,
    })

    const onClickDeposit = async () => {
      const ratioData = props.ratioData.find(d => d.currency === props.loginPlayerData.currency)

      await props.fetchAwcApi({
        api: 'deposit',
        requestData: {
          userId: props.loginPlayerData.userId,
          transferAmount: Number(ratioData.equivalentInTHB.toFixed(3)),
          txCode: new Date().valueOf(),
        },
        showMessage: true,
      })
    }

    const onClickLogout = () => props.fetchAwcApi({
      api: 'logout',
      requestData: {
        userIds: props.loginPlayerData.userId,
      },
      onSuccess: () => {
        props.onChangeLoginPlayerData() // empty loginPlayerData
        router.push('/testerportal/player-list')
      },
      onFail: (awcData) => {
        alertState.type = 'error'
        alertState.message = JSON.stringify(awcData)
      },
      showMessage: true,
    })

    return {
      showFrame,
      alertState,
      onClickCopy,
      onClickOpenFrame,
      onClickReload,
      onClickDeposit,
      onClickLogout,
    }
  },
}
</script>

<style scoped>
.iframe-wrapper {
  position: relative;
  height: 100%;
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.btn {
  margin: 28px 8px 0px;
}

.iframe-btns {
  position: absolute;
  bottom: 0;
  right: 0;
}

.iframe-btns .btn {
  margin: 0 0 0 8px;
}

.row {
  padding: 64px 24px;
}

.link {
  word-break: break-word;
}
</style>
